/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Table} = Object.assign({}, _provideComponents(), props.components);
  if (!Table) _missingMdxReference("Table", true);
  return React.createElement(Table, null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Faroese"), "\n", React.createElement("th", null, "Transliteration"), "\n", React.createElement("th", null, "English"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "Góðan morgun"), "\n", React.createElement("td", null, "gouwan morgun"), "\n", React.createElement("td", null, "Good morning")), React.createElement("tr", null, React.createElement("td", null, "Góðan dag"), "\n", React.createElement("td", null, "gouwan dae"), "\n", React.createElement("td", null, "Good day")), React.createElement("tr", null, React.createElement("td", null, "Gott kvøld"), "\n", React.createElement("td", null, "gott kvuld"), "\n", React.createElement("td", null, "Good evening")), React.createElement("tr", null, React.createElement("td", null, "Góða nátt"), "\n", React.createElement("td", null, "gouwa not"), "\n", React.createElement("td", null, "Good night")), React.createElement("tr", null, React.createElement("td", null, "Farvæl"), "\n", React.createElement("td", null, "farrvael"), "\n", React.createElement("td", null, "Goodbye"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
